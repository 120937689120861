/**************************\
  Basic Modal Styles
\**************************/

.modal {
	font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
}

.modal__overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal__container {
	background-color: #fff;
	padding: 30px;
	max-width: 700px;
	max-height: 100vh;
	border-radius: 4px;
	overflow-y: auto;
	box-sizing: border-box;
	z-index: 99999;
	color: var(--theme--dark);
}

.modal__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.modal__title {
	margin-top: 0;
	margin-bottom: 0;
	font-weight: 600;
	font-size: 2.5rem;
	padding-bottom: 0.5rem;
	line-height: 1.25;

	box-sizing: border-box;
}

.modal__subtitle {
	font-size: 1.5rem;
	text-transform: uppercase;
	margin-bottom: 0.5rem;
	font-weight: 800;
}
.modal-speakers ul {
	padding: 0;
}
.modal__close {
	background: transparent;
	border: 0;
}

.modal__header .modal__close:before {
	content: "\2715";
}

.modal__content {
	margin-top: 2rem;
	margin-bottom: 2rem;
	line-height: 1.5;
	color: rgba(0, 0, 0, 0.8);
}

.modal__btn {
	font-size: 0.875rem;
	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-color: #e6e6e6;
	color: rgba(0, 0, 0, 0.8);
	border-radius: 0.25rem;
	border-style: none;
	border-width: 0;
	cursor: pointer;
	-webkit-appearance: button;
	text-transform: none;
	overflow: visible;
	line-height: 1.15;
	margin: 0;
	will-change: transform;
	-moz-osx-font-smoothing: grayscale;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	transition: -webkit-transform 0.25s ease-out;
	transition: transform 0.25s ease-out;
	transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.modal__btn:focus,
.modal__btn:hover {
	-webkit-transform: scale(1.05);
	transform: scale(1.05);
}

.modal__btn-primary {
	background-color: #00449e;
	color: #fff;
}

/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes mmfadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes mmslideIn {
	from {
		transform: translateY(15%);
	}
	to {
		transform: translateY(0);
	}
}

@keyframes mmslideOut {
	from {
		transform: translateY(0);
	}
	to {
		transform: translateY(-10%);
	}
}

.micromodal-slide {
	display: none;
}

.micromodal-slide.is-open {
	display: block;
	position: relative;
	z-index: 3;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
	animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
	animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
	animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
	animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
	will-change: transform;
}
